<template>
  <div>
    <!-- <page-header :title="title" :container-class="containerClass" /> -->
    <div :class="containerClass" class="d-flex justify-content-between mt-5">
      <h2>
        Documents

        <i
          class="material-icons icon-16pt clickable-item"
          v-b-popover.hover.top="`Manage and download student's documents.`"
          >info</i
        >
      </h2>
      <span
        v-b-popover.hover.right="showMsg"
        v-if="
          [SCH_PAYMENT_PLANS.BASIC_PLAN, SCH_PAYMENT_PLANS.PAID_PLAN].includes(
            get(getLoggedInUser, 'linked_entity.sch_payment_plan.title')
          )
        "
      >
        <b-btn :disabled="!dateRange" class="btn-normal" @click.prevent="generateSharableReport" variant="primary">
          Generate Sharable Report</b-btn
        >
      </span>
    </div>

    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Students"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>
              <!-- <div class="" :class="isTabSmallScreen ? 'mb-2 col-12 d-flex justify-content-end' : 'col-2 pl-0'">
                <b-btn variant="secondary" @click.prevent="showFilters = true" v-if="!showFilters" size="sm">
                  <i class="fas fa-sliders-h mr-1" />Show Filters
                </b-btn>
                <b-btn variant="light" @click.prevent="showFilters = false" v-if="showFilters" size="sm">
                  <i class="fas fa-eye-slash mr-1" />Hide Filters
                </b-btn>
                <b-btn variant="light" class="ml-2" @click.prevent="clearFilters" v-if="program" size="sm">
                  <i class="fas fa-times mr-1" />Clear
                </b-btn>
              </div> -->

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <!-- TODO: Add filters here. (if needed) -->
                <b-btn
                  class="btn-normal"
                  variant="primary"
                  @click.prevent="exportTable"
                  :disabled="isLoading || isConfigPiiLoading"
                >
                  Export Table</b-btn
                >
                <!-- <b-dropdown text="Actions" variant="primary" id="dropdown-right" right>
                  <b-dropdown-item
                    :to="{ name: 'generate-report', params: { program: this.program, dateRange } }"
                    exact
                  >
                    <md-icon>insert_drive_file</md-icon>
                    Generate Sharable Report
                  </b-dropdown-item>

                  <b-dropdown-item @click.prevent="exportTable" :disabled="isLoading || isConfigPiiLoading">
                    <md-icon>file_download</md-icon> Export
                  </b-dropdown-item>
                </b-dropdown> -->
              </div>
              <!-- Filters -->
              <!-- <transition name="slide"> -->
              <div class="row mx-auto mb-2 w-100 mt-2">
                <div class="col-md-12 mb-2" :class="{ 'pl-0': isTabSmallScreen }">
                  <date-picker
                    v-model="dateRange"
                    format="DD MMMM, YYYY"
                    valueType="date"
                    id="date"
                    lang="en"
                    placeholder="Start Date Range"
                    range
                    @input="dateChange"
                    class="form-control datepicker-filter-custom w-100"
                  ></date-picker>
                </div>
                <div class="col-md-6 mb-2">
                  <v-select
                    id="state"
                    class="form-control v-select-custom"
                    label="title"
                    v-model="program"
                    :reduce="pg => pg.id"
                    placeholder="Select Program"
                    :options="allPrograms"
                    :loading="areProgramsLoading"
                    @input="changeProgram()"
                  >
                  </v-select>
                </div>
                <div class="col-md-6 mb-2 pl-0">
                  <v-select
                    id="tag"
                    class="form-control v-select-custom"
                    label="title"
                    v-model="tag_id"
                    :value="tag_id"
                    :reduce="sc => sc.id"
                    placeholder="Select Program Tag"
                    :options="allTags"
                    :loading="areTagsLoading && !allTags.length"
                    @search="fetchOptions"
                    :disabled="$route.name === 'edit-attendance' || (areTagsLoading && !allTags.length) || !program"
                    @input="applyFilter()"
                  >
                    <template #list-footer>
                      <li
                        v-observe-visibility="visibilityChanged"
                        v-show="allTags.length && allTags.length < totalTags"
                        class="loader"
                      ></li>
                    </template>
                    <template slot="option" slot-scope="option">
                      <span :id="`tag-${option.id}`">
                        {{ option.title }}
                      </span>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.title }}
                    </template>
                  </v-select>
                </div>
              </div>
              <!-- </transition> -->
            </div>
          </div>

          <!-- Students Table -->
          <b-skeleton-wrapper :loading="isLoading || isConfigPiiLoading">
            <template #loading>
              <b-skeleton-table :rows="5" :columns="4" :table-props="{ hover: true }"></b-skeleton-table>
            </template>
            <b-table
              :fields="tableColumns"
              :items="students"
              :busy="isLoading"
              head-variant="light"
              class="table-nowrap"
              hover
              responsive
              no-local-sorting
              @sort-changed="onSortChange"
            >
              <template #cell(avatar)="data">
                <user-avatar slot="aside" size="md" :user="data.item.user"> </user-avatar>
              </template>

              <template #cell(actions)="data">
                <span>
                  <a
                    v-b-popover.hover.right="'Upload document'"
                    href="#"
                    class="text-primary ml-3"
                    @click.prevent="openAddModal(data.item.id)"
                    ><i class="fas fa-upload"></i>
                    <!-- Upload File -->
                  </a>
                </span>
              </template>

              <template #cell(name)="data"
                ><strong> {{ data.item.user.first_name }} {{ data.item.user.last_name }}</strong></template
              >
              <template #cell(start_date)="data">
                {{ get(data.item, 'start_date') ? formatDateSimple(get(data.item, 'start_date')) : '' }}
              </template>

              <template #cell(program)="data">{{ get(data.item.enrolled_program, 'title') }} </template>

              <template v-for="doc in piiColumns" v-slot:[`cell(${doc.key})`]="data">
                <div :key="doc.key">
                  <a
                    href="#"
                    class="text-primary"
                    @click.prevent="
                      openSecModal(get(piis, `${doc.key}.title`), data.item.id, get(piis, `${doc.key}.type`))
                    "
                    v-if="data.item.student_piis.length && get(data.item.piisGrouped[doc.key], 'type')"
                    >{{ formatDocsDateTime(get(data.item.piisGrouped[doc.key], 'updated_at', '')) }}
                  </a>
                  <span class="d-flex justify-content-center" v-else>-</span>
                </div>
              </template>
            </b-table>

            <!-- Footer Pagination -->
            <div class="card-footer">
              <pagination
                v-model="currentPage"
                :total-rows="totalStudents"
                :per-page="perPage"
                @change="onPageChange"
                aria-controls="students-table"
              />
            </div>
          </b-skeleton-wrapper>
        </div>
      </div>
    </div>
    <secure-doc-modal
      :show-modal="showDocModal"
      :document-title="selectedDocTitle"
      :id="selectedStdId"
      :document-type="selectedDocType"
      @verification="tokenValidation"
      @close="hideViewDocument"
    />
    <security-modal
      :show-modal="showSecurityModal"
      :view-title="viewTitle"
      :view-id="viewId"
      :view-type="viewType"
      :action="action"
      @close="hideSecModal"
      @validated="userValidated"
      @sharable="moveToSharablePage"
    />

    <add-pii-modal :show-modal="showAddDocModal" :piiConfigs="piiConfigs" :userId="userId" @close="hideModal" />
    <b-modal ref="myModal" title="Downloading Report" centered hide-footer @hide="hideLoaderModal">
      <span v-if="isExporting">
        <i
          style="font-size: 3rem"
          class="d-flex justify-content-center fas fa-circle-notch fa-spin text-primary mb-2"
        ></i>
        <p class="text-70 text-center">Your file is being downloaded...</p>
      </span>
      <span v-else-if="fileUrl">
        <i
          class="d-flex justify-content-center fas fa-check-circle fa-lg text-success mb-4 mt-4"
          style="font-size: 3rem"
        />
        <p class="text-70 text-center">Your file has been downloaded.</p>
      </span>
      <span v-else-if="!fileUrl">
        <i
          class="d-flex justify-content-center fas fa-times-circle fa-lg text-danger mb-4 mt-4"
          style="font-size: 3rem"
        />
        <p class="text-70 text-center">Download Failed!</p>
      </span>
    </b-modal>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, keyBy, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import SecureDocModal from './SecureDocModal.vue';
import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, PII_CONFIG_TYPE, SCH_PAYMENT_PLANS } from '../../../common/constants';
import { formatDocsDateTime, formatToAPIDate, formatDateSimple } from '../../../common/utils';
import UserAvatar from '../../../components/User/UserAvatar.vue';
import SecurityModal from './SecurityModal.vue';
import AddPiiModal from './AddPiiModal.vue';
import DatePicker from 'vue2-datepicker';
import Vue from 'vue';
import { ObserveVisibility } from 'vue-observe-visibility';

Vue.directive('observe-visibility', ObserveVisibility);

export default {
  components: {
    MdIcon,
    // PageHeader,
    Pagination,
    UserAvatar,
    SecureDocModal,
    SecurityModal,
    AddPiiModal,
    DatePicker
  },
  extends: Page,

  data() {
    return {
      title: 'Documents',
      arr: [],
      piis: {},
      fileUrl: null,
      action: '',
      dateRange: null,
      isExporting: false,
      showFilters: false,
      showActions: false,
      isLoading: false,
      isConfigPiiLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalStudents: 0,
      students: [],
      showDocModal: false,
      showSecurityModal: false,
      selectedDocTitle: null,
      selectedStdId: null,
      selectedDocType: null,
      searchTerm: null,
      viewTitle: null,
      viewId: null,
      viewType: null,
      program: null,
      tag_id: null,
      isTokenExpired: false,
      piiConfigs: [],
      piiTypesConfig: {},
      userId: null,
      showAddDocModal: false,
      allPrograms: [],
      areProgramsLoading: false,
      piiColumns: [],
      windowWidth: window.innerWidth,
      SCH_PAYMENT_PLANS,
      allTags: [],
      areTagsLoading: false,
      limit: 15,
      offset: 0,
      totalTags: 0,
      search: ''
    };
  },

  watch: {
    program: {
      handler() {
        this.allTags = [];
        this.offset = 0;
        this.tag_id = null;
        this.search = '';
      }
    }
  },

  computed: {
    ...mapGetters('auth', ['getPiiVerificationToken', 'getLoggedInUser']),
    showMsg() {
      return !this.dateRange ? 'Select class start date to generate report' : '';
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('studentMsgs.students'), active: true }
      ];
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    tableColumns() {
      return [
        { key: 'actions', label: '' },
        { key: 'avatar', label: '' },
        { key: 'name', label: 'Name' },
        { key: 'start_date', label: 'Start Date' },
        { key: 'program', label: 'Enrolled Program' },

        ...(this.allTags.length ? [{ key: 'tag', label: 'Program Tag' }] : [])
      ].concat(this.piiColumns);
    }
  },

  methods: {
    get,
    formatDocsDateTime,
    ...mapActions('student', ['getStudentReqDocs', 'exportPiiTable']),
    ...mapActions('school', ['getSchoolConfigPiis']),
    ...mapActions('attendance', ['getTags']),
    ...mapActions('program', ['getSclPrograms']),
    ...mapActions('fileDownload', ['downloadFile']),
    formatDateSimple,

    visibilityChanged(reached) {
      if (reached) {
        this.offset += 15;
        this.fetchTags();
      }
    },

    async fetchTags() {
      this.tag_id = null;
      if (!this.search) this.areTagsLoading = true;
      const response = await this.getTags({
        ...(this.search && { search: this.search }),
        program__id: this.program,
        limit: this.limit,
        offset: this.offset
      });

      this.allTags = this.allTags.concat(response.data.data.results);
      this.totalTags = response.data.data.count;
      this.areTagsLoading = false;

      if (this.allTags.length > 15) {
        setTimeout(() => {
          const el = document.getElementById(`tag-${this.allTags.slice(-15)[0].id}`);

          if (el) {
            el.scrollIntoView({ behavior: 'instant', block: 'nearest' });
          }
        }, 100);
      }
    },

    debouncedSearchTag: debounce(async (loading, search, vm) => {
      vm.search = search;
      const response = await vm.getTags({
        search,
        program__id: vm.program,
        limit: vm.limit,
        offset: vm.offset
      });
      loading(false);

      vm.allTags = [];
      vm.totalTags = 0;
      vm.allTags = vm.allTags.concat(response.data.data.results);
      vm.totalTags = response.data.data.count;
    }, 500),

    async fetchOptions(search, loading) {
      if (!this.tag_id) {
        this.offset = 0;
        this.allTags = [];
        this.search = search;
        loading(true);
        this.debouncedSearchTag(loading, search, this);
      }
    },
    openSecModalForSharableReport(btnAction) {
      this.showSecurityModal = true;
      this.action = btnAction;
    },
    moveToSharablePage() {
      this.$router.push({
        name: 'generate-report',
        params: { program: this.program, dateRange: this.dateRange, tag_id: this.tag_id }
      });
    },
    generateSharableReport() {
      if (!this.getPiiVerificationToken || this.isTokenExpired) {
        this.openSecModalForSharableReport('sharableReport');
      } else {
        this.moveToSharablePage();
      }
    },
    async exportTable() {
      this.isExporting = true;
      this.openModal();
      try {
        const res = await this.exportPiiTable({
          ...(this.dateRange && {
            from_date: formatToAPIDate(this.dateRange[0]),
            to_date: formatToAPIDate(this.dateRange[1])
          }),
          program_id: this.program,
          selected_tag: this.tag_id
        });
        this.fileUrl = res.data.file_url;
        this.downloadGivenFile(this.fileUrl);
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isExporting = false;
    },
    openModal() {
      this.$refs.myModal.show();
    },
    hideLoaderModal() {
      this.$refs.myModal.hide();
    },
    async downloadGivenFile(url) {
      try {
        await this.downloadFile({ fileUrl: url, removeTimestamp: true });
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    clearFilters() {
      this.program = null;
      this.tag_id = null;
    },
    dateChange(value) {
      if (value[0]) {
        this.dateRange = value;
      } else {
        this.dateRange = null;
      }
      this.fetchStudentReqDocs();
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;
      try {
        const res = await this.getSclPrograms();
        this.allPrograms = res.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areProgramsLoading = false;
    },
    applyFilter() {
      this.fetchSclConfigPiis();
      this.fetchStudentReqDocs();
    },
    changeProgram() {
      this.tag_id = null;
      this.fetchTags();
      this.fetchSclConfigPiis();
      this.fetchStudentReqDocs();
    },
    openAddModal(id) {
      this.userId = id;
      this.showAddDocModal = true;
    },
    hideModal() {
      this.showAddDocModal = false;
      this.fetchStudentReqDocs();
    },

    tokenValidation(title, id, type) {
      this.isTokenExpired = true;
      this.openSecModal(title, id, type);
    },
    userValidated(title, id, type) {
      this.isTokenExpired = false;
      this.viewDocument(title, id, type);
    },
    openSecModal(title, id, type) {
      if (!this.getPiiVerificationToken || this.isTokenExpired) {
        this.showSecurityModal = true;
        this.viewTitle = title;
        this.viewId = id;
        this.viewType = type;
      } else {
        this.viewDocument(title, id, type);
      }
    },
    hideSecModal() {
      this.isTokenExpired = false;
      this.showSecurityModal = false;
      this.viewTitle = null;
      this.viewId = null;
      this.viewType = null;
      this.action = '';
    },
    viewDocument(title, id, type) {
      this.selectedDocTitle = title;
      this.selectedStdId = id;
      this.selectedDocType = type;
      this.showDocModal = true;
    },
    hideViewDocument() {
      this.showDocModal = false;
      this.selectedDocTitle = null;
      this.selectedStdId = null;
      this.selectedDocType = null;
    },
    async fetchStudentReqDocs(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getStudentReqDocs({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.program && { enrolled_program_id: this.program }),
        ...(this.tag_id && { selected_tag_id: this.tag_id }),
        ...(this.dateRange && {
          from_date: formatToAPIDate(this.dateRange[0]),
          to_date: formatToAPIDate(this.dateRange[1])
        }),
        ...params
      });
      this.students = response.data.results.map(std => ({
        ...std,
        piisGrouped: keyBy(std.student_piis, 'type')
      }));

      this.currentPage = pageNum;
      this.totalStudents = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchStudentReqDocs(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchStudentReqDocs();
    },

    onSearch() {
      this.debouncedSearchStudentReqDocs(this);
    },

    debouncedSearchStudentReqDocs: debounce(vm => {
      vm.fetchStudentReqDocs();
    }, 500),
    getOverallStatus(data) {
      if (data.config_programs.some(obj => obj.status === 'required')) {
        return 'required';
      } else if (data.config_programs.some(obj => obj.status === 'optional')) {
        return 'optional';
      } else {
        return 'disabled';
      }
    },
    async fetchSclConfigPiis() {
      this.isConfigPiiLoading = true;
      try {
        const res = await this.getSchoolConfigPiis({
          ...(this.program && { program_id: this.program }),
          ...(this.tag_id && { selected_tag_id: this.tag_id })
        });

        this.piiConfigs = res.data.filter(pii => this.getOverallStatus(pii) !== PII_CONFIG_TYPE.DISABLED);
        this.piiColumns = this.piiConfigs.map(({ type, title }) => ({ key: type, label: title }));
        res.data.forEach(pii => {
          this.piiTypesConfig[pii.type] = this.getOverallStatus(pii);
        });
        this.piis = keyBy(this.piiConfigs, 'type');
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isConfigPiiLoading = false;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);

    this.fetchPrograms();
    this.fetchSclConfigPiis();
    this.fetchStudentReqDocs();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
